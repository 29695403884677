[data-site-theme='ucma'] [data-theme='ultraLightYellow'],
[id='modal'] [data-theme='ultraLightYellow'] {
  // Background
  --ui01: #f9eed6;

  // Text
  --textLight: #ccd1da;
  --textRegular: #001c47;
  --textUltraAccent: #e0960b;
  --textHighEmphasis: #001c47;

  // Button Text
  --textPrimaryButton01: #001c47;
  --textPrimaryHover01: #fff;
  --textPrimaryDisabled01: #fff;

  --textPrimaryButton02: #001c47;
  --textPrimaryHover02: #001c47;
  --textPrimaryDisabled02: #f9eed6;

  --textSecondaryButton: #001c47;
  --textSecondaryHover: #001c47;
  --textSecondaryDisabled: #c7c4b9;

  --textLinkButton: #001c47;
  --textLinkHover: #001c47;
  --textLinkDisabled: #c7c4b9;

  // Button Background
  --bgPrimaryButton01: #f7ab1b;
  --bgPrimaryButtonHover01: #001c47;
  --bgPrimaryButtonDisabled01: #ccd1da;

  --bgPrimaryButton02: #ccd1da;
  --bgPrimaryButtonHover02: #adbbd4;
  --bgPrimaryButtonDisabled02: #fff;

  --bgSecondaryButton: #f9eed6;
  --bgSecondaryButtonHover: #edcd85;
  --bgSecondaryButtonDisabled: #f9eed6;

  // Button Border
  --borderSecondaryButton: #001c47;
  --borderSecondaryHover: #001c47;
  --borderSecondaryDisabled: #c7c4b9;

  --borderLinkButton: #001c47;
  --borderLinkHover: #001c47;
  --borderLinkDisabled: #c7c4b9;
}
