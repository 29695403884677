:root {
  // Fonts
  --font-primary: Graphik, Arial, Helvetica, sans-serif;
  --font-secondary: Cooper, 'Times New Roman';
  --font-tabs: GraphikMedium, sans-serif;

  // Background Colors
  --ui01: #ffffff;
  --ui02: #f8f8f8;
  --ui03: #ebedf0;
  --ui04: #f9eed6;
  --ui05: #f7ab1b;
  --ui06: #001c47;

  // Text Colors
  --textRegular: #001c47;
  --textAccent: #f7ab1b;
  --textUltraAccent: #e0960b;
  --textLight: #ccd1da;
  --textInverse: #ffffff;
  --textHighEmphasis: #001c47;

  --link01: #e0960b;
  --linkHover01: #e0960b;
  --linkActive01: #e0960b;
  --linkVisited01: #e0960b;

  // error
  --errorMessage: #ff3a21;

  // ODS Colors
  --ods-01: #e5243b;
  --ods-02: #dda83a;
  --ods-03: #4c9f38;
  --ods-04: #c5192d;
  --ods-05: #ff3a21;
  --ods-06: #26bde2;
  --ods-07: #fcc30b;
  --ods-08: #a21942;
  --ods-09: #fd6925;
  --ods-10: #dd1367;
  --ods-11: #fd9d24;
  --ods-12: #bf8b2e;
  --ods-13: #3f7e44;
  --ods-14: #0a97d9;
  --ods-15: #56c02b;
  --ods-16: #00689d;
  --ods-17: #19486a;

  // Area Background Colors
  --area-bg01: #afd9c0;
  --area-bg02: #9dc0c9;
  --area-bg03: #404670;
  --area-bg04: #f9eed6;

  // Gradient Colors
  --gradient01: linear-gradient(180deg, #abd9e3 0%, #9ccdd4 100%);
  --gradient-bg: linear-gradient(180deg,
          rgba(0, 28, 71, 0.3) 0%,
          #001c47 81.77%);

  // Veil Colors
  --modal-bg: rgba(0, 22, 55, 0.85);
  --header-veil: rgba(0, 28, 71, 0.6);
  --video-veil: rgba(0, 28, 71, 0.3);

  // Border Radius
  --radii-xs: 3px;
  --radii-s: 8px;
  --radii-m: 16px;
  --radii-l: 32px;
  --radii-xl: 60px;
  --radii-xxl: 100px;
  --radii-circle: 999px;

  // Spacing
  --spc-xxxs: 8px;
  --spc-xxs: 16px;
  --spc-xs: 24px;
  --spc-s: 32px;
  --spc-m: 40px;
  --spc-l: 48px;
  --spc-xl: 56px;
  --spc-xxl: 64px;
  --spc-xxxl: 72px;
  --spc-4xl: 96px;
  --spc-5xl: 128px;
  --spc-6xl: 256px;

  // Out of Scale Spacing
  --outSpc-20: 20px;
  --outSpc-80: 80px;
  --outSpc-144: 144px;

  // Borders
  --border-style01: solid;

  // Widths and Containers
  --width-line01: 1px;
  --small-container: 600px;
  --medium-container: 900px;
  --big-container: 1100px;

  // Shadows
  --inactive-shadow: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.03)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
  --hover-shadow: drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.15)) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.05));


  // Layout Sizes
  --max-width-xs: 296px;
  --max-width-s: 648px;
  --max-width-m: 840px;
  --max-width-l: 1128px;

  --z-modal: 300;
  --z-modal-buttom: 400;
  --z-header: 200;
}

$max-width-l-landing: 1508px;
$landing-margin: 40px;
:root{
  // Landing
  --max-width-l-landing: #{$max-width-l-landing};

  --landing-margin: #{$landing-margin};

  --landing-form-gap: 33.33%;
}
