[data-site-theme='ucma'] [data-theme='blue'],
[id='modal'] [data-theme='blue'] {
  // Background
  --ui01: #001c47;

  // Text
  --textLight: #ccd1da;
  --textRegular: #ffffff;
  --textAccent: #e0960b;
  --textUltraAccent: #f7ab1b;
  --textHighEmphasis: #001c47;
  
  // Button Text
  --textPrimaryButton01: #001c47;
  --textPrimaryHover01: #001c47;
  --textPrimaryDisabled01: #404670;

  --textPrimaryButton02: #001c47;
  --textPrimaryHover02: #001c47;
  --textPrimaryDisabled02: #404670;

  --textSecondaryButton: #fff;
  --textSecondaryHover: #fff;
  --textSecondaryDisabled: #404670;

  --textLinkButton: #fff;
  --textLinkHover: #fff;
  --textLinkDisabled: #565e95;

  // Button Background
  --bgPrimaryButton01: #f7ab1b;
  --bgPrimaryButtonHover01: #ffffff;
  --bgPrimaryButtonDisabled01: #001637;

  --bgPrimaryButton02: #ccd1da;
  --bgPrimaryButtonHover02: #adbbd4;
  --bgPrimaryButtonDisabled02: #001637;

  --bgSecondaryButton: #001c47;
  --bgSecondaryButtonHover: #001637;
  --bgSecondaryButtonDisabled: #001e4b;

  // Button Border
  --borderSecondaryButton: #fff;
  --borderSecondaryHover: #fff;
  --borderSecondaryDisabled: #404670;

  --borderLinkButton: #fff;
  --borderLinkHover: #fff;
  --borderLinkDisabled: #565e95;
}
