:root {
  font-family: var(--font-primary);
  color: var(--textRegular);
  background-color: var(--ui01);
}

.grecaptcha-badge {
  visibility: hidden;
}

.lineDecoration {
  position: absolute;
  width: 100%;
  height: 100%;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}
