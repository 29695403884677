.img {
  &-svg {
    width: auto;
    padding-right: 10px;
  }

  &--rotate {
    transform: rotate(var(--rotate-icon)) translate(3px);
    transition: all .8s ease;
  }

  &-p0 {
    padding: 0;
  }
}
