[data-site-theme='ucma'] [data-theme='yellow'],
[id='modal'] [data-theme='yellow'] {
  // Background
  --ui01: #f7ab1b;

  // Text
  --textLight: #ccd1da;
  --textRegular: #001c47;
  --textUltraAccent: #ffffff;
  --textHighEmphasis: #001c47;

  // Button Text
  --textPrimaryButton01: #001c47;
  --textPrimaryHover01: #fff;
  --textPrimaryDisabled01: #edcd85;

  --textPrimaryButton02: #001c47;
  --textPrimaryHover02: #001c47;
  --textPrimaryDisabled02: #f7ab1b;

  --textSecondaryButton: #001c47;
  --textSecondaryHover: #001c47;
  --textSecondaryDisabled: #c68f24;

  --textLinkButton: #001c47;
  --textLinkHover: #001c47;
  --textLinkDisabled: #c68f24;

  // Button Background
  --bgPrimaryButton01: #ffffff;
  --bgPrimaryButtonHover01: #001c47;
  --bgPrimaryButtonDisabled01: #e0960b;

  --bgPrimaryButton02: #ccd1da;
  --bgPrimaryButtonHover02: #adbbd5;
  --bgPrimaryButtonDisabled02: #e0960b;

  --bgSecondaryButton: #f7ab1b;
  --bgSecondaryButtonHover: #e0960b;
  --bgSecondaryButtonDisabled: #f7ab1b;

  // Button Border
  --borderSecondaryButton: #001c47;
  --borderSecondaryHover: #001c47;
  --borderSecondaryDisabled: #c68f24;

  --borderLinkButton: #001c47;
  --borderLinkHover: #001c47;
  --borderLinkDisabled: #c68f24;
}
