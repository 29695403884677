[data-site-theme='ucma'] [data-theme='ultraLightBlue'],
[id='modal'] [data-theme='ultraLightBlue'] {
  // Background
  --ui01: #f8f8f8;

  // Text
  --textLight: #ccd1da;
  --textRegular: #001c47;
  --textUltraAccent: #e0960b;
  --textHighEmphasis: #001c47;

  // Button Text
  --textPrimaryButton01: #001c47;
  --textPrimaryHover01: #fff;
  --textPrimaryDisabled01: #fff;

  --textPrimaryButton02: #001c47;
  --textPrimaryHover02: #001c47;
  --textPrimaryDisabled02: #fff;

  --textSecondaryButton: #001c47;
  --textSecondaryHover: #001c47;
  --textSecondaryDisabled: #ccd1da;

  --textLinkButton: #001c47;
  --textLinkHover: #001c47;
  --textLinkDisabled: #ccd1da;

  // Button Background
  --bgPrimaryButton01: #f7ab1b;
  --bgPrimaryButtonHover01: #001c47;
  --bgPrimaryButtonDisabled01: #ebedf0;

  --bgPrimaryButton02: #ccd1da;
  --bgPrimaryButtonHover02: #adbbd4;
  --bgPrimaryButtonDisabled02: #ebedf0;

  --bgSecondaryButton: #f8f8f8;
  --bgSecondaryButtonHover: #ccd1da;
  --bgSecondaryButtonDisabled: #f8f8f8;

  // Button Border
  --borderSecondaryButton: #001c47;
  --borderSecondaryHover: #001c47;
  --borderSecondaryDisabled: #ccd1da;

  --borderLinkButton: #001c47;
  --borderLinkHover: #001c47;
  --borderLinkDisabled: #ccd1da;
}
