@import '@foundations/text-styles.scss';

table {
  thead {
    background: var(--ui03);
  }

  td {
    padding: var(--spc-xxs);
    width: calc(4 * 100% / 12);
    vertical-align: top;
    @extend .body-default;
  }
}

table, th, td {
  border: 1px solid #CCD1DA;
  border-collapse: collapse;
}